

import { mapActions, mapGetters } from 'vuex'
import KeyFigureBlock from '../components/home/KeyFigureBlock'

export default {
  name: 'Index',
  components: { KeyFigureBlock },
  beforeRouteLeave (to, from, next) {
    this.clearMessage()
    next()
  },
  head () {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })
    return {
      title: this.$i18n.t('seo.home.title'),
      htmlAttrs: {
        ...i18nHead.htmlAttrs
      },
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$i18n.t('seo.home.description')
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.$i18n.t('seo.home.keyWords')
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:title',
          content: this.$i18n.t('seo.home.title')
        },
        {
          property: 'og:url',
          content: 'https://' + this.$i18n.localeProperties.domain + this.$route.fullPath
        },
        {
          property: 'og:description',
          content: this.$i18n.t('seo.home.description')
        },
        {
          property: 'twitter:card',
          content: 'summary'
        },
        {
          property: 'twitter:site',
          content: '@Enerdata'
        },
        {
          property: 'twitter:title',
          content: this.$i18n.t('seo.home.title')
        },
        {
          property: 'twitter:description',
          content: this.$i18n.t('seo.home.description')
        },
        ...i18nHead.meta
      ],
      link: [
        ...i18nHead.link
      ]
    }
  },
  computed: {
    ...mapGetters({
      keyEstimateBlocks: 'home/keyEstimateBlocks',
      keyDataBlocks: 'home/keyDataBlocks',
      keyDecarbonizationBlocks: 'home/keyDecarbonizationBlocks',
      defaultPagePath: 'defaultPagePath',
      defaultPageTitle: 'defaultPageTitle',
      message: 'home/message',
      loggedIn: 'loggedIn'
    }),
    pageLandscapeImage () {
      return require('../assets/images/jumbotron/homepage.jpg')
    }
  },
  mounted () {
    this.clearPageContent()
  },
  methods: {
    ...mapActions({
      clearPageContent: 'page/clearPageContent',
      clearMessage: 'home/clearMessage'
    })
  }
}
