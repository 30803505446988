
export default {
  name: 'KeyFigureBlock',
  props: {
    keyFigureData: {
      required: true,
      type: Object
    },
    nbColXl: {
      type: Number,
      default: 4
    },
    nbColLg: {
      type: Number,
      default: 4
    },
    nbColMd: {
      type: Number,
      default: 2
    },
    nbColSm: {
      type: Number,
      default: 1
    },
    nbColXs: {
      type: Number,
      default: 1
    }
  },
  computed: {
    colXlSize () {
      return 12 / this.nbColXl
    },
    colLgSize () {
      return 12 / this.nbColLg
    },
    colMdSize () {
      return 12 / this.nbColMd
    },
    colSmSize () {
      return 12 / this.nbColSm
    },
    colXsSize () {
      return 12 / this.nbColXs
    },
    hasTwoValues () {
      return this.keyFigureData.secondValue !== undefined
    }
  }
}
